<template>
  <div class="mores">
    <my-header diyRouter="home"></my-header>
    <div class="title f14 c333 text-left pt10 mb25">
      精选课程<span class="f30 pl15">Concentration</span>
    </div>
    <div class="list" ref="container">
      <course-list
        v-for="(item, index) in list"
        :key="index"
        :item="item"
      ></course-list>
      <div class="noMsg" v-if="list.length == 0 && noMsgShow">
        <div class="img"><img src="../../assets/img/courseMsg.jpg" /></div>
        <div class="text-center f24 c333 pt10">课程为空</div>
      </div>
    </div>
  </div>
</template>

<script>
import myHeader from '../../components/head.vue'
import courseList from '../../components/course-list.vue'
import { Scroll } from '@/utils/loading'
export default {
  name: 'choice',
  components: {
    myHeader,
    courseList
  },
  data() {
    return {
      id: '',
      list: [],
      page: 1,
      length: 0,
      noMsgShow: false,
      loading: false
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getList()
    // 下拉加载
    this.$scroll(this.$refs.container, () => {
      if (this.length > this.list.length) {
        !this.loading && this.getList()
      }
    })
    Scroll.listenTouchDirection()
  },
  computed: {
    disabled() {
      return this.list.length >= this.length
    }
  },
  methods: {
    async getList() {
      this.loading = true
      var params = {
        collectionId: this.id,
        page: this.page,
        pagesize: 20
      }
      var res = await this.$api.get(
        '/api/project_collection/collectionDetail',
        params
      )
      if (res.code == 0) {
        var data = res.data.list
        this.list = [...this.list, ...data]
        this.length = res.data.count
      }
      this.noMsgShow = true
      this.loading = false
      this.page = this.page + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.mores {
  height: 100%;
  padding: 40px 40px;
  .list {
    overflow: auto;
    height: calc(100% - 110px);
    position: relative;
  }
  .title span {
    color: #5b68fb;
  }
  .hd {
    .back {
      color: #6672fb;
      span {
        font-weight: bold;
      }
      img {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 15px;
      }
    }
  }
}
</style>
